<template>
	<main class="page-container container">
		<header class="page__header" :class="[isMobileMedium ? 'flex-wrap' : '', isMobileSmall ? 'mb-10' : 'mb-30']">
			<app-text
				tag="h1"
				size="26"
				line-height="34"
				weight="600"
				:class="[isMobileMedium ? 'w-100' : '', isMobileMedium ? 'mb-20' : '']"
				class="mr-30"
				>{{ $t("standardFormsAndContracts") }}
			</app-text>
		</header>

		<div class="section__wrapper" :class="isMobileSmall ? 'mb-40' : 'mb-80'">
			<app-content-card padding-x="0" padding-y="0" :radius="[8, 8, 0, 0]">
				<app-tabs class="tab-standard" v-model="selectedTab" :list="tabItems" />
			</app-content-card>

			<template v-if="selectedTab === 'modelContracts'">
				<div class="mt-20">
					<div class="normative-acts__item">
						<AppText size="16" weight="600" class="mb-20">
							{{ $t("LawsOfUzb") }}
						</AppText>

						<ul class="custom-list">
<!--							<li class="custom-list__item">-->
<!--								<a-->
<!--									:href="`/docs/standard-forms/contarcts-withoutpercent-${$store.state.clng}.docx`"-->
<!--									download-->
<!--								>-->
<!--									&lt;!&ndash; <a  download> &ndash;&gt;-->
<!--									<div class="d-flex file-container">-->
<!--										<div class="file-icon mr-10">-->
<!--											<img-->
<!--												src="../../../src/assets/icons/word.png"-->
<!--												width="22"-->
<!--												height="22"-->
<!--												alt=""-->
<!--											/>-->
<!--										</div>-->
<!--										<span class="custom-list__link"> {{ $t("titleOfWithPercent") }} </span>-->
<!--										<div class="file-size color-text-light fs-12 ml-auto">22 kB</div>-->
<!--									</div>-->
<!--								</a>-->
<!--							</li>-->
<!--							<li class="custom-list__item">-->
<!--								&lt;!&ndash; :href="`/docs/standard-forms/model-contracts-2-${$store.state.clng}.docx`" &ndash;&gt;-->
<!--								<a-->
<!--									download-->
<!--									:href="`/docs/standard-forms/contarcts-withpercent-${$store.state.clng}.docx`"-->
<!--								>-->
<!--									<div class="d-flex file-container">-->
<!--										<div class="file-icon mr-10">-->
<!--											<img-->
<!--												src="../../../src/assets/icons/word.png"-->
<!--												width="22"-->
<!--												height="22"-->
<!--												alt=""-->
<!--											/>-->
<!--										</div>-->
<!--										<span class="custom-list__link"> {{ $t("titleOfWithoutPercent") }} </span>-->
<!--										<div class="file-size color-text-light fs-12 ml-auto">24 kB</div>-->
<!--									</div>-->
<!--								</a>-->
<!--							</li>-->

							<li v-for="(item, idx) in listOfDocuments" :key="idx" class="custom-list__item">
								<a
									href="javascript:void(0)"
									@click="downloadTemplateFile(item)"
								>
									<!-- <a  download> -->
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link"> {{ item.downloadName }} </span>
										<div class="file-size color-text-light fs-12 ml-auto">22 kB</div>
									</div>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</template>
			<!-- <template v-if="selectedTab === 'typicalProtocols'">
				<div class="mt-20">
					<div class="normative-acts__item">
						<ul class="custom-list">
							<li class="custom-list__item">
								<a
									:href="`/docs/standard-forms/typical-protocols-1-${$store.state.clng}.docx`"
									download
								>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText01") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											19 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a
									:href="`/docs/standard-forms/typical-protocols-2-${$store.state.clng}.docx`"
									download
								>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText02") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											19 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a
									:href="`/docs/standard-forms/typical-protocols-3-${$store.state.clng}.docx`"
									download
								>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText03") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											17 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a
									:href="`/docs/standard-forms/typical-protocols-4-${$store.state.clng}.docx`"
									download
								>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText04") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											17 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a
									:href="`/docs/standard-forms/typical-protocols-5-${$store.state.clng}.docx`"
									download
								>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText05") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											23 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a
									:href="`/docs/standard-forms/typical-protocols-6-${$store.state.clng}.docx`"
									download
								>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText06") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											29 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a
									:href="`/docs/standard-forms/typical-protocols-7-${$store.state.clng}.docx`"
									download
								>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText07") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											19 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a
									:href="`/docs/standard-forms/typical-protocols-8-${$store.state.clng}.docx`"
									download
								>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText08") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											17 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a :href="`/docs/standard-forms/typical-protocols-9-${$store.state.clng}.docx`" download>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText09") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											19 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a :href="`/docs/standard-forms/typical-protocols-10-${$store.state.clng}.docx`" download>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText10") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											17 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a :href="`/docs/standard-forms/typical-protocols-11-${$store.state.clng}.docx`" download>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText11") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											19 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a :href="`/docs/standard-forms/typical-protocols-12-${$store.state.clng}.docx`" download>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText12") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											19 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a :href="`/docs/standard-forms/typical-protocols-13-${$store.state.clng}.docx`" download>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText13") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											19 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a :href="`/docs/standard-forms/typical-protocols-14-${$store.state.clng}.docx`" download>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText14") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											17 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a :href="`/docs/standard-forms/typical-protocols-15-${$store.state.clng}.docx`" download>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText15") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											20 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a :href="`/docs/standard-forms/typical-protocols-16-${$store.state.clng}.docx`" download>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText16") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											20 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a :href="`/docs/standard-forms/typical-protocols-17-${$store.state.clng}.docx`" download>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText17") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											17 kB
										</div>
									</div>
								</a>
							</li>
							<li class="custom-list__item">
								<a :href="`/docs/standard-forms/typical-protocols-18-${$store.state.clng}.docx`" download>
									<div class="d-flex file-container">
										<div class="file-icon mr-10">
											<img
												src="../../../src/assets/icons/word.png"
												width="22"
												height="22"
												alt=""
											/>
										</div>
										<span class="custom-list__link">
											{{ $t("protocolText18") }}
										</span>
										<div class="file-size color-text-light fs-12 ml-auto">
											19 kB
										</div>
									</div>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</template> -->
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import AppContentCard from "@/components/shared-components/AppContentCard";
import AppTabs from "@/components/shared-components/AppTabs";
import axios from "axios";
export default {
	name: "index",
	components: { AppText, AppContentCard, AppTabs },
	data() {
		return {
			selectedTab: "modelContracts",
			tabItems: [
				{
					name: this.$i18n.t("modelContracts"),
					value: "modelContracts",
				},
				{
					name: this.$i18n.t("typicalProtocols"),
					value: "typicalProtocols",
				},
			],
			documentFormUz: [
				{
					downloadName: 'Shartnomaning namunaviy shakli (agar buyurtmachi byudjet tashkiloti bo‘lsa hamda yetkazib beruvchi nogironligi bor tashkilot bo‘lsa, yaʼni shartnoma to‘g‘ridan-to‘g‘ri to‘liq to‘lov asosida shakllangan bo‘lsa)',
					path: '/docs/standard-forms/1.docx'
				},
				{
					downloadName: 'Shartnomaning namunaviy shakli (agar buyurtmachi koorporativ tashkiloti bo‘lsa hamda yetkazib beruvchi nogironligi bor tashkilot bo‘lsa, yaʼni shartnoma to‘g‘ridan-to‘g‘ri oldindan to‘lov asosida shakllangan bo‘lsa)',
					path: '/docs/standard-forms/2.docx'
				},
				{
					downloadName: 'Shartnomaning namunaviy shakli (agar buyurtmachi koorporativ tashkiloti bo‘lsa hamda yetkazib beruvchi nogironligi bor tashkilot bo‘lsa, yaʼni shartnoma to‘g‘ridan-to‘g‘ri to‘liq to‘lov asosida shakllangan bo‘lsa)',
					path: '/docs/standard-forms/3.docx'
				},
				{
					downloadName: 'Shartnomaning namunaviy shakli (elektron katalog, byudjet-to‘liq to‘lov)',
					path: '/docs/standard-forms/4.docx'
				},
				{
					downloadName: 'Shartnomaning namunaviy shakli (elektron katalog, koorporativ-oldindan to‘lov)',
					path: '/docs/standard-forms/5.docx'
				},
				{
					downloadName: 'Shartnomaning namunaviy shakli (elektron katalog, koorporativ-to‘liq to‘lov)',
					path: '/docs/standard-forms/6.docx'
				}
			],
			documentFormUzCyril: [
				{
					downloadName: 'Шартноманинг намунавий шакли (агар буюртмачи бюджет ташкилоти бўлса ҳамда етказиб берувчи ногиронлиги бор ташкилот бўлса, яъни шартнома тўғридан-тўғри тўлиқ тўлов асосида шаклланган бўлса)',
					path: '/docs/standard-forms/1.docx'
				},
				{
					downloadName: 'Шартноманинг намунавий шакли (агар буюртмачи коорпоратив ташкилоти бўлса ҳамда етказиб берувчи ногиронлиги бор ташкилот бўлса, яъни шартнома тўғридан-тўғри олдиндан тўлов асосида шаклланган бўлса)',
					path: '/docs/standard-forms/2.docx'
				},
				{
					downloadName: 'Шартноманинг намунавий шакли (агар буюртмачи коорпоратив ташкилоти бўлса ҳамда етказиб берувчи ногиронлиги бор ташкилот бўлса, яъни шартнома тўғридан-тўғри тўлиқ тўлов асосида шаклланган бўлса)',
					path: '/docs/standard-forms/3.docx'
				},
				{
					downloadName: 'Шартноманинг намунавий шакли (электрон каталог, бюджет-тўлиқ тўлов)',
					path: '/docs/standard-forms/4.docx'
				},
				{
					downloadName: 'Шартноманинг намунавий шакли (электрон каталог, коорпоратив-олдиндан тўлов)',
					path: '/docs/standard-forms/5.docx'
				},
				{
					downloadName: 'Шартноманинг намунавий шакли (электрон каталог, коорпоратив-тўлиқ тўлов)',
					path: '/docs/standard-forms/6.docx'
				}
			],
			documentFormRu: [
				{
					downloadName: 'Типовая форма договора (электронный каталог, бюджет-польная оплата)',
					path: '/docs/standard-forms/4-ru.docx',
				},
				{
					downloadName: 'Типовая форма договора (электронный каталог, коорпоратив-предоплата)',
					path: '/docs/standard-forms/5-ru.docx',
				},
				{
					downloadName: 'Типовая форма договора (электронный каталог, коорпоратив-польная оплата)',
					path: '/docs/standard-forms/6-ru.docx',
				}
			]
		};
	},
	methods: {
		async downloadTemplateFile({path,downloadName}) {
			await axios.get(path, {responseType: 'arraybuffer'}).then(res=>{
				let blob = new Blob([res.data])
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = downloadName + '.docx'
				link._target = 'blank'
				link.click();
				link.remove()
			})
		}
	},
	computed: {
		listOfDocuments: function () {
			if(this.$store.state.clng === 'ru') {
				return this.documentFormRu
			} else if(this.$store.state.clng === 'cyrl') {
				return this.documentFormUzCyril
			} else if(this.$store.state.clng === 'uz') {
				return this.documentFormUz
			} else return []
		}
	}
};
</script>

<style lang="scss" scoped>
.normative-acts {
	&__item {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.file-container {
	align-items: center;
}

.file-size {
	white-space: nowrap;
}
</style>

